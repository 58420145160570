import React, { useState, useEffect } from "react";
import { Container, Col, Row, Form, Spinner } from "react-bootstrap";
import "../CreateEmployee/CreateEmployee.css";
import Input from "../../../../../Components/Input/Input";
import Button from "../../../../../Components/Button/Button";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import { createUserForm } from "../../../../../Utils/Validation";
import { useDispatch, useSelector } from "react-redux";
import {
  activeEmployeeByID,
  inActiveEmployeeByID,
  updateEmployeeById,
} from "../../../../../Redux/Actions/Employee";
import {
  getAllEmployeesDropdown,
  getEmployeeDetails,
} from "../../../../../Redux/Actions/HR";
import Loader from "../../../../../Utils/Loader";
import moment from "moment";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import Header from "../../../../../Components/Header/Header";
import {
  getAllDepartments,
  getAllDesignations,
  getAllLeaves,
  getAllShifts,
} from "../../../../../Redux/Actions/HR";
import Select from "react-select";
import MetaData from "../../../../../Components/MetaData";
import Modal from "../../../../../Components/SiteModal/SiteModal";
import InputMask from "react-input-mask";

const EditEmployee = () => {
  const { id } = useParams();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { employee, loading, error } = useSelector((state) => state.employee);
  const {
    loading: updateLoading,
    isUpdated,
    error: updateError,
  } = useSelector((state) => state.employeeUpdate);
  const { loading: departmentLoading, departments } = useSelector(
    (state) => state.departments
  );
  const { loading: designationLoading, designations } = useSelector(
    (state) => state.designations
  );
  const { loading: activeLoading, active } = useSelector(
    (state) => state.activeEmployee
  );
  const { loading: inActiveLoading, inactive } = useSelector(
    (state) => state.inActiveEmployee
  );
  const { loading: leavesLoading, leaves } = useSelector(
    (state) => state.leaves
  );
  const { loading: shiftLoading, shifts } = useSelector(
    (state) => state.shifts
  );
  const { loading: employeeDropdownLoading, employeeDropdown } = useSelector(
    (state) => state.employeeDropdown
  );
  const [selectedOption, setSelectedOption] = useState([]);
  const [defaultLeaves, setDefaultLeaves] = useState([]);
  const [selectedLeaves, setSelectedLeaves] = useState([]);

  const [userDocuments, setUserDocuments] = useState({
    HSC: "",
    SSC: "",
    appointment_letter: "",
    bachelors_degree: "",
    office_policy: "",
    utility_bill: "",
    masters_degree: "",
    diploma_degree: "",
    experience_letter: "",
    last_payslip: "",
    resume: "",
    photo: "",
  });
  const [CNIC_pictures, setCNIC_pictures] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [showActiveModal, setShowActiveModal] = useState(false);
  const [showInActiveModal, setShowInActiveModal] = useState(false);
  const [CNICNumber, setCNICNumber] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const onFileChangeHandler = (name) => (e) => {
    const files = Array.from(e.target.files);
    if (name === "CNIC_pictures") {
      setCNIC_pictures([]);
    }
    if (name === "certificates") {
      setCertificates([]);
    }
    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          if (name === "CNIC_pictures") {
            setCNIC_pictures((old) => [...old, reader.result]);
          }
          if (name === "certificates") {
            setCertificates((old) => [...old, reader.result]);
          }
          setUserDocuments({
            ...userDocuments,
            [name]: reader.result,
          });
        }
      };

      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    setSelectedOption(
      Array.isArray(defaultLeaves) ? defaultLeaves.map((x) => x.label) : []
    );
  }, [defaultLeaves]);

  const options = leaves?.map((d) => {
    let { _id, __v, ...rest } = d;
    return {
      value: rest,
      label: d.leave_type,
    };
  });

  //Find values that are in result1 but not in result2
  var leaveSelected = employee?.employee?.leaves.map((s) => {
    let { _id, ...rest } = s;
    return {
      value: rest,
      label: s.leave_type,
    };
  });

  var remainingOptions = options?.filter(function (option) {
    // Check if option is not in leaveSelected based on label comparison
    return !leaveSelected?.some(function (leave) {
      return option.label === leave.label;
    });
  });

  const handleOffChange = (selectedOption) => {
    console.log(selectedOption, "selectedOptionselectedOption");
    setSelectedLeaves(selectedOption);
    // setSelectedLeaves(Array.isArray(selectedOption) ? selectedOption.map((x) => x) : []);
  };

  useEffect(() => {
    if (employee?.success === true) {
      setValue(
        "full_name",
        employee?.employee?.full_name ? employee?.employee?.full_name : "-"
      );
      setValue(
        "father_husband_name",
        employee?.employee?.father_husband_name
          ? employee?.employee?.father_husband_name
          : "-"
      );
      setValue(
        "DOB",
        employee?.employee?.DOB
          ? moment(employee?.employee?.DOB).format("YYYY-MM-DD")
          : "-"
      );
      setValue(
        "gender",
        employee?.employee?.gender ? employee?.employee?.gender : "-"
      );
      setValue(
        "personal_email",
        employee?.employee?.personal_email
          ? employee?.employee?.personal_email
          : "-"
      );
      setValue(
        "marital_status",
        employee?.employee?.marital_status
          ? employee?.employee?.marital_status
          : "-"
      );
      setCNICNumber(employee?.employee?.CNIC);
      setValue(
        "phone",
        employee?.employee?.phone ? employee?.employee?.phone : "-"
      );
      setValue(
        "ECE_name",
        employee?.employee?.ECE_name ? employee?.employee?.ECE_name : "-"
      );
      setValue(
        "ECE_number",
        employee?.employee?.ECE_number ? employee?.employee?.ECE_number : "-"
      );
      setValue(
        "address",
        employee?.employee?.address ? employee?.employee?.address : "-"
      );
      setValue(
        "ECE_relation",
        employee?.employee?.ECE_relation
          ? employee?.employee?.ECE_relation
          : "-"
      );
      setValue("role", employee?.employee.role ? employee?.employee.role : "-");
      setValue(
        "skype_link",
        employee?.employee?.skype_link ? employee?.employee?.skype_link : "-"
      );
      setValue(
        "job_title",
        employee?.employee?.job_title ? employee?.employee.job_title : "-"
      );
      setValue(
        "salary",
        employee?.employee?.salary ? employee?.employee.salary : "-"
      );
      setValue(
        "department",
        employee?.employee?.department
          ? employee?.employee?.department?._id
          : "-"
      );
      setValue(
        "designation",
        employee?.employee?.designation
          ? employee?.employee?.designation?._id
          : "-"
      );
      setValue(
        "line_manager",
        employee?.employee?.line_manager
          ? employee?.employee?.line_manager?._id
          : "-"
      );

      setValue(
        "joining_date",
        employee?.employee.joining_date
          ? moment(employee?.employee.joining_date).format("YYYY-MM-DD")
          : "-"
      );
      setValue(
        "permanent_date",
        moment(employee?.employee.permanent_date).format("YYYY-MM-DD")
      );
      setValue(
        "email",
        employee?.employee?.email ? employee?.employee.email : "-"
      );
      setValue(
        "shift",
        employee?.employee?.shift ? employee?.employee.shift?._id : "-"
      );
      setValue(
        "employment_status",
        employee?.employee?.employment_status
          ? employee?.employee.employment_status
          : "-"
      );
      // setValue(
      //   "fuel_allowance",
      //   employee?.employee?.fuel_allowance
      //     ? employee?.employee.fuel_allowance
      //     : 0
      // );
      setValue(
        "leaves",
        employee?.employee?.leaves ? employee?.employee?.leaves : "-"
      );
      setValue(
        "medical_status",
        employee?.employee?.medical_status
          ? employee?.employee.medical_status
          : "-"
      );
      setValue(
        "account_title",
        employee?.employee?.account_title
          ? employee?.employee.account_title
          : "-"
      );
      setValue(
        "account_number",
        employee?.employee?.account_number
          ? employee?.employee.account_number
          : "-"
      );
      setValue(
        "bank_name",
        employee?.employee?.bank_name ? employee?.employee.bank_name : "-"
      );
      setValue(
        "branch",
        employee?.employee.branch ? employee?.employee.branch : "-"
      );
      setValue(
        "bank_branch_address",
        employee?.employee?.bank_branch_address
          ? employee?.employee.bank_branch_address
          : "-"
      );
      setValue(
        "bank_branch_code",
        employee?.employee?.bank_branch_code
          ? employee?.employee.bank_branch_code
          : "-"
      );
      setSelectedLeaves(
        employee?.employee?.leaves.map((s) => {
          let { _id, ...rest } = s;

          return {
            value: rest,
            label: s.leave_type,
          };
        })
      );
    }
  }, [employee?.success, setValue]);

  useEffect(() => {
    dispatch(getAllDepartments());
    dispatch(getAllDesignations());
    dispatch(getAllLeaves());
    dispatch(getAllShifts());
    dispatch(getAllEmployeesDropdown());
  }, []);

  useEffect(() => {
    dispatch(getEmployeeDetails(id));
  }, []);

  useEffect(() => {
    if (isUpdated?.success) {
      successNotify(isUpdated?.message);
      reset();
      navigation(-1);
      dispatch({ type: "UPDATE_EMPLOYEE_RESET" });
    }
    if (updateError) {
      errorNotify(updateError);
      dispatch({ type: "CLEAR_ERRORS" });
    }
    if (active?.success) {
      successNotify(active?.message);
      setShowActiveModal(false);
      navigation(-1);
      dispatch({ type: "ACTIVE_EMPLOYEE_RESET" });
    }
    if (inactive?.success) {
      successNotify(inactive?.message);
      setShowInActiveModal(false);
      navigation(-1);
      dispatch({ type: "IN_ACTIVE_EMPLOYEE_RESET" });
    }
  }, [isUpdated?.success, active, inactive, updateError]);

  const onSubmitHandler = handleSubmit(async (data) => {
    const userData = {
      ...data,
      leaves:
        Array.isArray(selectedLeaves) &&
        selectedLeaves.map((item) => item.value),
      CNIC: CNICNumber,
      documents: {
        CNIC_pictures: CNIC_pictures && CNIC_pictures,
        HSC: userDocuments.HSC && userDocuments.HSC,
        SSC: userDocuments.SSC && userDocuments.SSC,
        appointment_letter:
          userDocuments.appointment_letter && userDocuments.appointment_letter,
        bachelors_degree:
          userDocuments.bachelors_degree && userDocuments.bachelors_degree,
        utility_bill: userDocuments.utility_bill && userDocuments.utility_bill,
        masters_degree:
          userDocuments.masters_degree && userDocuments.masters_degree,
        diploma_degree:
          userDocuments.diploma_degree && userDocuments.diploma_degree,
        certificates: certificates && certificates,
        experience_letter:
          userDocuments.experience_letter && userDocuments.experience_letter,
        last_payslip: userDocuments.last_payslip && userDocuments.last_payslip,
        resume: userDocuments.resume && userDocuments.resume,
        photo: userDocuments.photo && userDocuments.photo,
      },
    };

    await dispatch(updateEmployeeById(id, userData));

    dispatch(getEmployeeDetails(id));
  });
  const handleEmployeeActive = () => {
    setShowActiveModal(!showActiveModal);
  };
  const handleEmployeeInActive = () => {
    setShowInActiveModal(!showInActiveModal);
  };

  const ActiveModal = (
    <Modal
      title="Activate Employee"
      size={"lg"}
      show={showActiveModal}
      onCloseModal={() => setShowActiveModal(!showActiveModal)}
    >
      <React.Fragment>
        <p className="modal_Text">
          Are you sure you want to Activate this account?
        </p>
        <div className="modal_decision">
          <button
            className="my-1 bg_red"
            onClick={() => dispatch(activeEmployeeByID(id))}
          >
            {activeLoading ? <Spinner animation="border" size="sm" /> : "Yes"}
          </button>
          <button
            className="my-1 no_btn"
            onClick={() => setShowActiveModal(false)}
          >
            No
          </button>
        </div>
      </React.Fragment>
    </Modal>
  );
  const InActiveModal = (
    <Modal
      title="Inactivate Employee"
      size={"lg"}
      show={showInActiveModal}
      onCloseModal={() => setShowInActiveModal(!showInActiveModal)}
    >
      <React.Fragment>
        <p className="modal_Text">
          Are you sure you want to Inactivate this account?
        </p>
        <div className="modal_decision">
          <button
            className="my-1 bg_red"
            onClick={() => dispatch(inActiveEmployeeByID(id))}
          >
            {inActiveLoading ? <Spinner animation="border" size="sm" /> : "Yes"}
          </button>
          <button
            className="my-1 no_btn"
            onClick={() => setShowInActiveModal(false)}
          >
            No
          </button>
        </div>
      </React.Fragment>
    </Modal>
  );

  return (
    <div className="page_responsive">
      <Header title="EDIT EMPLOYEE" />
      <MetaData
        title={`${
          !loading ? employee?.employee?.full_name : "Employee"
        } -- HRM`}
      />

      <div className="create_emp_bg_background">
        <Container>
          {loading ||
          shiftLoading ||
          designationLoading ||
          departmentLoading ||
          leavesLoading ? (
            <Loader />
          ) : (
            <Form onSubmit={onSubmitHandler}>
              <Row>
                <Col md={6}>
                  <div className="personal_details_container">
                    <div className="details_head">
                      <h5>Personal Details</h5>
                    </div>

                    <div className="details_container">
                      <Row>
                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                              type="text"
                              {...register(
                                "full_name",
                                createUserForm.full_name
                              )}
                              placeholder="Enter Your Full Name"
                            />
                          </Input>
                          {errors.full_name ? (
                            <small className="text-danger">
                              {errors.full_name && errors.full_name.message}
                            </small>
                          ) : null}
                        </Col>
                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Father/Husband Name</Form.Label>
                            <Form.Control
                              type="text"
                              {...register(
                                "father_husband_name",
                                createUserForm.father_husband_name
                              )}
                              placeholder="Enter Your Father/Husband Name"
                            />
                          </Input>
                          {errors.father_husband_name ? (
                            <small className="text-danger">
                              {errors.father_husband_name &&
                                errors.father_husband_name.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Date of Birth</Form.Label>
                            <Form.Control
                              type="date"
                              {...register("DOB", createUserForm.DOB)}
                            />
                          </Input>
                          {errors.DOB ? (
                            <small className="text-danger">
                              {errors.DOB && errors.DOB.message}
                            </small>
                          ) : null}
                        </Col>
                        <Col md={6} className="my-1">
                          <Form.Label>Gender</Form.Label>
                          <div key={`inline-radio-1`} className="mb-3">
                            <Form.Check
                              defaultChecked={
                                employee?.employee?.gender === "male"
                              }
                              inline
                              label="Male"
                              type={"radio"}
                              id={`inline-radio-1`}
                              value={"male"}
                              {...register("gender", createUserForm.gender)}
                            />
                            <Form.Check
                              defaultChecked={
                                employee?.employee?.gender === "female"
                              }
                              inline
                              label="Female"
                              type={"radio"}
                              id={`inline-radio-2`}
                              value={"female"}
                              {...register("gender", createUserForm.gender)}
                            />
                            <br />
                            {errors.gender ? (
                              <small className="text-danger">
                                {errors.gender && errors.gender.message}
                              </small>
                            ) : null}
                          </div>
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              {...register(
                                "personal_email",
                                createUserForm.personal_email
                              )}
                              placeholder="Enter Your Email Address"
                            />
                          </Input>
                          {errors.personal_email ? (
                            <small className="text-danger">
                              {errors.personal_email &&
                                errors.personal_email.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Marital Status</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              {...register(
                                "marital_status",
                                createUserForm.marital_status
                              )}
                            >
                              <option defaultValue={""}></option>
                              <option value="Single">Single</option>
                              <option value="Married">Married</option>
                              <option value="Divorced">Divorced</option>
                            </Form.Select>
                          </Input>
                          {errors.marital_status ? (
                            <small className="text-danger">
                              {errors.marital_status &&
                                errors.marital_status.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          {/* <Input>
                            <Form.Label>CNIC</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("CNIC", createUserForm.CNIC)}
                              placeholder="Enter Your CNIC"
                            />
                          </Input> */}
                          <Form.Label>CNIC</Form.Label>
                          <div className="nic__input_mask">
                            <InputMask
                              type={"text"}
                              placeholder="Enter Your CNIC"
                              onChange={(e) => {
                                setCNICNumber(e.target.value);
                              }}
                              value={CNICNumber}
                              mask="99999-9999999-9"
                              maskChar=" "
                            />
                          </div>

                          {errors.CNIC ? (
                            <small className="text-danger">
                              {errors.CNIC && errors.CNIC.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("phone", createUserForm.phone)}
                              placeholder="Enter Your Phone"
                            />
                          </Input>
                          {errors.phone ? (
                            <small className="text-danger">
                              {errors.phone && errors.phone.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={12} className="my-1">
                          <Input>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("address", createUserForm.address)}
                              placeholder="Enter Your Address"
                              // value={employee?.employee.address}
                            />
                          </Input>
                          {errors.address ? (
                            <small className="text-danger">
                              {errors.address && errors.address.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Emergency Contact Name</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("ECE_name", createUserForm.ECE_name)}
                              placeholder="Enter Name"
                              // value={employee?.employee.ECE_name}
                            />
                          </Input>
                          {errors.ECE_name ? (
                            <small className="text-danger">
                              {errors.ECE_name && errors.ECE_name.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Emergency Contact Number</Form.Label>
                            <Form.Control
                              type="text"
                              {...register(
                                "ECE_number",
                                createUserForm.ECE_number
                              )}
                              // value={employee?.employee.ECE_number}
                              placeholder="Enter Number"
                            />
                          </Input>
                          {errors.ECE_number ? (
                            <small className="text-danger">
                              {errors.ECE_number && errors.ECE_number.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={12} className="my-1">
                          <Input>
                            <Form.Label>
                              Relation With Emergency Contact
                            </Form.Label>
                            <Form.Control
                              type="text"
                              {...register("ECE_relation")}
                              placeholder="Enter relation name"
                            />
                          </Input>
                          {/* {errors.address ? (
                            <small className="text-danger">
                              {errors.address && errors.address.message}
                            </small>
                          ) : null} */}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="personal_details_container">
                    <div className="details_head">
                      <h5>Job Details</h5>
                    </div>

                    <div className="details_container">
                      <Row>
                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Role</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              {...register("role", createUserForm.role)}
                            >
                              <option defaultValue={-1} key={-1}>
                                Please Select Role
                              </option>
                              <option value="employee">Employee</option>
                              <option value="HR">HR</option>
                              <option value="manager">Manager</option>
                            </Form.Select>
                          </Input>

                          {errors.role ? (
                            <small className="text-danger">
                              {errors.role && errors.role.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Skype Link</Form.Label>
                            <Form.Control
                              type="text"
                              {...register(
                                "skype_link",
                                createUserForm.skype_link
                              )}
                              placeholder="Enter Your Skype Link"
                            />
                          </Input>
                          {errors.skype_link ? (
                            <small className="text-danger">
                              {errors.skype_link && errors.skype_link.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Salary</Form.Label>
                            <Form.Control
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              {...register("salary", createUserForm.salary)}
                              placeholder="Enter Salary"
                            />
                          </Input>
                          {errors.salary ? (
                            <small className="text-danger">
                              {errors.salary && errors.salary.message}
                            </small>
                          ) : null}
                        </Col>
                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Department</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              {...register(
                                "department",
                                createUserForm.department
                              )}
                            >
                              <option defaultValue={-1} key={-1} value="">
                                Select Department
                              </option>
                              {departments &&
                                departments?.map((department, i) => (
                                  <option value={department._id} key={i}>
                                    {department?.department_name}
                                  </option>
                                ))}
                            </Form.Select>
                          </Input>
                          {errors.department ? (
                            <small className="text-danger">
                              {errors.department && errors.department.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Line Manager</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              {...register(
                                "line_manager",
                                createUserForm.line_manager
                              )}
                            >
                              <option defaultValue={-1} key={-1} value="">
                                Select Line Manager
                              </option>
                              {!employeeDropdownLoading &&
                                employeeDropdown?.employees?.map(
                                  (manager, i) => (
                                    <option value={manager._id} key={i}>
                                      {manager.full_name}
                                    </option>
                                  )
                                )}
                            </Form.Select>
                          </Input>

                          {errors.manager ? (
                            <small className="text-danger">
                              {errors.manager && errors.manager.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>WebMail ID</Form.Label>
                            <Form.Control
                              type="email"
                              {...register("email", createUserForm.email)}
                              placeholder="Enter Webmail ID"
                            />
                          </Input>
                          {errors.email ? (
                            <small className="text-danger">
                              {errors.email && errors.email.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Designation</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              {...register(
                                "designation",
                                createUserForm.designation
                              )}
                            >
                              <option defaultValue={-1} key={-1} value="">
                                Select Designation
                              </option>
                              {designations &&
                                designations?.map((designation, i) => (
                                  <option value={designation._id} key={i}>
                                    {designation.designation_name}
                                  </option>
                                ))}
                            </Form.Select>
                          </Input>
                          {errors.designation ? (
                            <small className="text-danger">
                              {errors.designation && errors.designation.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Date of Joining</Form.Label>
                            <Form.Control
                              type="date"
                              {...register(
                                "joining_date",
                                createUserForm.joining_date
                              )}
                            />
                          </Input>
                          {errors.joining_date ? (
                            <small className="text-danger">
                              {errors.joining_date &&
                                errors.joining_date.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Permanent Date</Form.Label>
                            <Form.Control
                              type="date"
                              {...register(
                                "permanent_date",
                                createUserForm.permanent_date
                              )}
                            />
                          </Input>
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Shift Timing</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              {...register("shift", createUserForm.shift)}
                            >
                              <option defaultValue={-1} key={-1}>
                                Please Select Timing
                              </option>
                              {shifts &&
                                shifts.map((shift) => (
                                  <option value={shift._id}>
                                    {shift.shift_name}
                                  </option>
                                ))}
                            </Form.Select>
                          </Input>
                          {errors.shift ? (
                            <small className="text-danger">
                              {errors.shift && errors.shift.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Employment Status</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              {...register(
                                "employment_status",
                                createUserForm.employment_status
                              )}
                            >
                              <option defaultValue={-1} key={-1}>
                                Please Select Employement
                              </option>
                              <option value="Internship">Internship</option>
                              <option value="Probation">Probation</option>
                              <option value="Contractual">Contractual</option>
                              <option value="Permanent">Permanent</option>
                            </Form.Select>
                          </Input>

                          {errors.employment_status ? (
                            <small className="text-danger">
                              {errors.employment_status &&
                                errors.employment_status.message}
                            </small>
                          ) : null}
                        </Col>

                        {/* <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Fuel Allowance</Form.Label>
                            <Form.Control
                              {...register(
                                "fuel_allowance",
                                createUserForm.fuel_allowance
                              )}
                              type="text"
                              placeholder="Enter Fuel Allowance"
                            />
                          </Input>
                          {errors.fuel_allowance ? (
                            <small className="text-danger">
                              {errors.fuel_allowance &&
                                errors.fuel_allowance.message}
                            </small>
                          ) : null}
                        </Col> */}

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Medical Status</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("medical_status")}
                              placeholder="Enter Medical Status"
                            />
                          </Input>
                          {/* {errors.line_manager ? (
                            <small className="text-danger">
                              {errors.line_manager &&
                                errors.line_manager.message}
                            </small>
                          ) : null} */}
                        </Col>
                        <Col md={12} className="my-1">
                          <Form.Label>Leaves</Form.Label>
                          <Select
                            defaultValue={
                              selectedLeaves?.length > 0
                                ? selectedLeaves
                                : employee?.employee?.leaves.map((s) => {
                                    let { _id, ...rest } = s;

                                    return {
                                      value: rest,
                                      label: s.leave_type,
                                    };
                                  })
                            }
                            isMulti
                            // isClearable
                            name="leaves"
                            placeholder="Select Leaves"
                            options={remainingOptions}
                            // value={remainingOptions}
                            onChange={handleOffChange}
                            // className="basic-multi-select form-control"
                            classNamePrefix="select"
                          />

                          {errors.leaves_status ? (
                            <small className="text-danger">
                              {errors.leaves_status &&
                                errors.leaves_status.message}
                            </small>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="personal_details_container">
                    <div className="details_head">
                      <h5>Bank Details</h5>
                    </div>

                    <div className="details_container">
                      <Row>
                        <Col md={6}>
                          <Input>
                            <Form.Label>Account Title Name</Form.Label>
                            <Form.Control
                              type="text"
                              {...register(
                                "account_title",
                                createUserForm.account_title
                              )}
                              placeholder="Enter Account Title Name"
                            />
                          </Input>
                          {errors.account_title ? (
                            <small className="text-danger">
                              {errors.account_title &&
                                errors.account_title.message}
                            </small>
                          ) : null}
                        </Col>
                        <Col md={6}>
                          <Input>
                            <Form.Label>Account Number</Form.Label>
                            <Form.Control
                              type="text"
                              {...register(
                                "account_number",
                                createUserForm.account_number
                              )}
                              placeholder="Enter Account Number"
                            />
                          </Input>
                          {errors.account_number ? (
                            <small className="text-danger">
                              {errors.account_title &&
                                errors.account_number.message}
                            </small>
                          ) : null}
                        </Col>
                        <Col md={6}>
                          <Input>
                            <Form.Label>Bank Name</Form.Label>
                            <Form.Control
                              type="text"
                              {...register(
                                "bank_name",
                                createUserForm.bank_name
                              )}
                              placeholder="Enter Bank Name"
                            />
                          </Input>
                          {errors.account_number ? (
                            <small className="text-danger">
                              {errors.bank_name && errors.bank_name.message}
                            </small>
                          ) : null}
                        </Col>
                        <Col md={6}>
                          <Input>
                            <Form.Label>Branch Name</Form.Label>
                            <Form.Control
                              {...register("branch", createUserForm.branch)}
                              type="text"
                              placeholder="Enter Bank Name"
                            />
                          </Input>
                          {errors.branch ? (
                            <small className="text-danger">
                              {errors.branch && errors.branch.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6}>
                          <Input>
                            <Form.Label>Bank Branch Address</Form.Label>
                            <Form.Control
                              type="text"
                              {...register(
                                "bank_branch_address",
                                createUserForm.bank_branch_address
                              )}
                              placeholder="Enter Bank Branch Address"
                            />
                          </Input>
                          {errors.branch ? (
                            <small className="text-danger">
                              {errors.branch && errors.branch.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6}>
                          <Input>
                            <Form.Label>Bank Branch Code</Form.Label>
                            <Form.Control
                              type="text"
                              {...register(
                                "bank_branch_code",
                                createUserForm.bank_branch_code
                              )}
                              placeholder="Enter Bank Branch Code"
                            />
                          </Input>
                          {errors.bank_branch_code ? (
                            <small className="text-danger">
                              {errors.bank_branch_code &&
                                errors.bank_branch_code.message}
                            </small>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="personal_details_container">
                    <div className="details_head">
                      <h5>Document Details</h5>
                    </div>

                    <div className="details_container">
                      <Row>
                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>Resume</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={onFileChangeHandler("resume")}
                              placeholder="Enter Your Full Name"
                            />
                          </Input>
                        </Col>
                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>Appointment Letter</Form.Label>
                            <Form.Control
                              onChange={onFileChangeHandler(
                                "appointment_letter"
                              )}
                              type="file"
                            />
                          </Input>
                        </Col>

                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>Utility Bills</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={onFileChangeHandler("utility_bill")}
                            />
                          </Input>
                        </Col>
                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>CNIC Both Front/Back</Form.Label>
                            <Form.Control
                              multiple
                              type="file"
                              onChange={onFileChangeHandler("CNIC_pictures")}
                            />
                          </Input>
                        </Col>

                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>Passport Size Photo</Form.Label>
                            <Form.Control
                              multiple
                              type="file"
                              onChange={onFileChangeHandler("photo")}
                            />
                          </Input>
                        </Col>

                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>HSC</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={onFileChangeHandler("HSC")}
                            />
                          </Input>
                        </Col>
                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>SSC</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={onFileChangeHandler("SSC")}
                            />
                          </Input>
                        </Col>

                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>Bachelor Degree</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={onFileChangeHandler("bachelors_degree")}
                            />
                          </Input>
                        </Col>

                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>Masters Degree</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={onFileChangeHandler("masters_degree")}
                            />
                          </Input>
                        </Col>

                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>Experience Letter</Form.Label>
                            <Form.Control
                              type="file"
                              // {...register("experience_letter")}
                              onChange={onFileChangeHandler(
                                "experience_letter"
                              )}
                            />
                          </Input>
                        </Col>

                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>Last PaySlip</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={onFileChangeHandler("last_payslip")}
                            />
                          </Input>
                        </Col>

                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>Certificates</Form.Label>
                            <Form.Control
                              type="file"
                              multiple
                              onChange={onFileChangeHandler("certificates")}
                            />
                          </Input>
                        </Col>

                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>Diploma Degree</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={onFileChangeHandler("diploma_degree")}
                            />
                          </Input>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>

              <Col md={12}>
                <div className="d-flex justify-content-end py-3 update_employee_btn_container">
                  {!employee?.employee?.isActive ? (
                    <button
                      type="button"
                      className="emp_active_btn"
                      onClick={() => handleEmployeeActive()}
                    >
                      ACTIVATE
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="emp_in_active_btn"
                      onClick={() => handleEmployeeInActive()}
                    >
                      INACTIVATE
                    </button>
                  )}
                  <Button type="submit" disabled={updateLoading ? true : false}>
                    {updateLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "UPDATE"
                    )}
                  </Button>
                </div>
              </Col>
            </Form>
          )}
        </Container>
      </div>
      {ActiveModal}
      {InActiveModal}
    </div>
  );
};

export default EditEmployee;
