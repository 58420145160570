import React, { useEffect, useState } from "react";
import { Container, Row, Col, DropdownButton, Dropdown } from "react-bootstrap";
import moment from "moment/moment";
import { MoreHoriz, MoreVert } from "@mui/icons-material";
import {
  deleteAnnouncementById,
  getAnnouncementData,
} from "../../../../../Redux/Actions/Announcement";
import { useDispatch, useSelector } from "react-redux";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import { clearErrors } from "../../../../../Redux/Actions/HR";
import { BsTrashFill } from "react-icons/bs";
import parse from "html-react-parser";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import EditAnnouncement from "./EditAnnouncement";
import { BaseURL } from "../../../../../Utils/interceptors";
import axios from "axios";
import { toast } from "react-toastify";
import { getToken } from "../../../../../Utils/helper";
const GetAnnouncement = ({ announceData, page }) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectetedData, setSelectetedData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { loading, isDeleted, error } = useSelector(
    (state) => state.deleteAnnouncement
  );
  const { user } = useSelector((state) => state.currentUser);
  useEffect(() => {
    if (isDeleted?.success) {
      successNotify(isDeleted?.message);
      dispatch(getAnnouncementData(page));
      dispatch({ type: "DELETE_ANNOUNCEMENT_RESET" });
    }
    if (error) {
      errorNotify(error);
      dispatch(clearErrors());
    }
  }, [isDeleted?.success, error]);

  const handleDeleteAnnouncement = (id) => {
    dispatch(deleteAnnouncementById(id));
  };

  const handleEdit = async (params) => {
    const apiUrl = BaseURL + `/announcement/${selectetedData?._id}`;
    const token = getToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    };
    setIsLoading(true);
    const response = await axios.put(apiUrl, params, options);
    if (response !== undefined) {
      toast.success("Edit successful");
      setModalOpen(false);
      dispatch(getAnnouncementData(1));
    }
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <Container>
        <Row>
          {announceData?.map((announcementData, index) => {
            return (
              <Col md={11} className={"create_announcement"} key={index}>
                <div className="header_line"></div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="form_div">
                    <img
                      src={
                        announcementData?.created_by?.profile_picture[0]
                          ?.fileName
                      }
                      alt="avatar"
                    />
                    <div>
                      <h6>{announcementData?.created_by.full_name}</h6>
                      <h5>{moment(announcementData?.date).format("lll")}</h5>
                    </div>
                  </div>
                  {user?.user?.role === "HR" && (
                    <DropdownButton
                      className="edit_employee_dropdown"
                      id="dropdown-basic-button"
                      title={<MoreHoriz />}
                    >
                      <Dropdown.Item
                        eventKey={index}
                        onClick={() => {
                          setSelectetedData(announcementData);
                          setModalOpen(true);
                        }}
                      >
                        <DriveFileRenameOutlineIcon className="btn_edit_Icon" />{" "}
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          handleDeleteAnnouncement(announcementData?._id)
                        }
                      >
                        <BsTrashFill className="btn_ban_Icon" />
                        Delete{" "}
                      </Dropdown.Item>
                    </DropdownButton>
                  )}
                </div>
                <hr style={{ color: "#00000054" }} />

                <div className="announcement_img_show">
                  <p style={{ color: "rgb(115 110 110)" }}>
                    {parse(announcementData?.text)}
                  </p>
                  {!(announcementData?.picture?.length === 0) ? (
                    <img
                      src={announcementData?.picture[0].url}
                      alt="img"
                      className="show_img"
                    />
                  ) : null}
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
      {modalOpen && (
        <EditAnnouncement
          data={selectetedData}
          setEditModalOpen={setModalOpen}
          editModalOpen={modalOpen}
          onClick={handleEdit}
          isLoading={isLoading}
        />
      )}
    </React.Fragment>
  );
};

export default GetAnnouncement;
