import React, { useState } from "react";
import ReactModal from "../../../../../Components/Modal/Modal";
import { Box, InputLabel } from "@mui/material";
import classes from "./EditAnnouncement.module.css";
import ReactQuill from "react-quill";
import { Spinner } from "react-bootstrap";
import Button from "../../../../../Components/Button/Button";
import { toast } from "react-toastify";
import { FaImages, FaShare } from "react-icons/fa";
import Modal from "../../../../../Components/SiteModal/SiteModal";
import Group from "../../../../../Asssets/Images/group.jpg";
import { useSelector } from "react-redux";
import Loader from "../../../../../Utils/Loader";
import { getUser } from "../../../../../Utils/helper";
import dummyProfile from "../../../../../Asssets/Images/profile.png";
const EditAnnouncement = ({
  editModalOpen,
  setEditModalOpen,
  onClick,
  isLoading,
  data,
}) => {
  const { loading: employeeLoading, employees } = useSelector(
    (state) => state.employees
  );
  let userAuth = getUser() ? getUser() || "" : null;
  const [showModal, setShowModal] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [previewFile, setPreviewFile] = useState(null);
  const [getImage, setGetImage] = useState({});
  const [description, setDescription] = useState(data?.text || "");
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    if (employees?.success) {
      setIsCheck(
        employees?.employees?.map((li) => {
          if (!(li._id === userAuth.employee._id)) {
            return li._id;
          }
        })
      );
    }
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  let showUser;
  if (employees?.success) {
    showUser = employees?.employees?.map((e) => {
      if (!(e.email === userAuth?.employee.email)) {
        return (
          <li className="mb-1 pb-2 pt-2 user_list_item" key={e._id}>
            <div className="list_name">
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  className="user_img"
                  src={
                    e?.profile_picture[0]?.fileName
                      ? e?.profile_picture[0]?.fileName
                      : dummyProfile
                  }
                  alt="avatar"
                />
                <h6>{e.full_name}</h6>
              </div>
              <input
                id={e._id}
                name={e.full_name}
                type={"checkbox"}
                onClick={handleClick}
                checked={isCheck.includes(e._id)}
              />
            </div>
          </li>
        );
      }
    });
  }
  const modal = (
    <ReactModal title="Share With" open={showModal} setOpen={setShowModal}>
      <div className="announcement_modal">
        {employeeLoading ? (
          <Loader />
        ) : (
          <>
            <ul className={`user_list ${classes.users__list}`}>
              <li className="mb-1 pb-2 user_list_item">
                <div
                  className="list_name"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img className="user_img" src={Group} alt="avatar" />
                    <h6>Select All</h6>
                  </div>
                  <input
                    className="user_select"
                    id={"selectAll"}
                    name={"selectAll"}
                    type={"checkbox"}
                    onClick={handleSelectAll}
                    checked={isCheckAll}
                  />
                </div>
              </li>

              {showUser}
            </ul>
            <div className="mt-3 share_with_btn_container">
              <button onClick={() => setShowModal(!showModal)}>Submit</button>
            </div>
          </>
        )}
      </div>
    </ReactModal>
  );

  const onImageHandler = (name) => (e) => {
    setPreviewFile(e.target.files[0].name);
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setGetImage({
            ...getImage,
            [name]: reader.result,
          });
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleEdit = () => {
    const params = {
      text: description,
      users: isCheck,
      picture: getImage?.announcement_img,
    };

    if (!description) {
      return toast.error("Please fill the description field");
    }

    if (isCheck?.length === 0) {
      return toast.error("Please add users");
    }

    onClick(params);
  };

  return (
    <>
      <ReactModal
        open={editModalOpen}
        setOpen={setEditModalOpen}
        title="Edit Announcement"
      >
        <Box sx={{ my: 2 }}>
          <InputLabel className={classes.department_label} sx={{ mb: 1 }}>
            {/* Description */}
          </InputLabel>
          <ReactQuill
            theme="snow"
            value={description}
            onChange={setDescription}
          />
          <div className="file_div">
            <label>
              <FaImages />
              Upload Image
              <input
                type="file"
                onChange={onImageHandler("announcement_img")}
              />
            </label>

            <label onClick={() => setShowModal(!showModal)}>
              <FaShare />
              Share with
            </label>
          </div>
          <div className={classes.btnMain}>
            <Button
              onClick={handleEdit}
              type="submit"
              className="announcement_create_btn"
            >
              {isLoading ? <Spinner animation="border" size="sm" /> : "Submit"}
            </Button>
          </div>
        </Box>
      </ReactModal>
      {modal}
    </>
  );
};

export default EditAnnouncement;
