import { Avatar, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import DashBoardCard from "../../../../Components/DashboardCard/DashBoardCard";
import Header from "../../../../Components/Header/Header";
import Clock from "../../../../Asssets/Images/clock.png";
import RedClock from "../../../../Asssets/Images/checkout.png";
import { RxDotFilled } from "react-icons/rx";
import "./dashboard.css";
import { useStyle } from "./DashboardStyles";
import Ticket from "../../../../Asssets/Images/Tickets.png";
import Leave from "../../../../Asssets/Images/Leave.png";
import Calendar from "../../../../Components/big-Calender/Calender";
import { useEffect } from "react";
import { getDashboardData } from "../../../../Redux/Actions/HR";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../../../Utils/Loader";
import MetaData from "../../../../Components/MetaData";
import { getAllRequests } from "../../../../Redux/Actions/Requests";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, dashboard } = useSelector((state) => state.dashboardData);
  const { loading: requestsLoading, requests } = useSelector(
    (state) => state.getRequests
  );
  useEffect(() => {
    dispatch(getDashboardData());
    dispatch(getAllRequests(0, 5));
  }, [dispatch]);

  const classes = useStyle();

  return (
    <div className="page_responsive">
      <Header title="DASHBOARD" />
      <MetaData title={`Dashboard -- HRM`} />

      {loading ? (
        <Loader />
      ) : (
        <div>
          <Box sx={{ margin: "20px 30px" }}>
            <Grid container spacing={3}>
              <DashBoardCard
                style={{ backgroundColor: "#8b65ef" }}
                value={dashboard?.totalEmployees}
                heading="Employees"
                sub="TOTAL"
              />
              <DashBoardCard
                style={{ backgroundColor: "#59C680" }}
                value={dashboard?.presents?.totalPresents}
                heading="Present"
                sub="TOTAL"
              />
              <DashBoardCard
                style={{ backgroundColor: "#FE022C" }}
                value={dashboard?.absents?.totalAbsents}
                heading="Absent"
                sub="TOTAL"
              />
              <DashBoardCard
                style={{ backgroundColor: "#14D4E2" }}
                value={dashboard?.todayOnleaves}
                heading="On Leaves"
                sub="EMPLOYEES"
              />
            </Grid>
          </Box>
          <Box sx={{ margin: "30px 30px" }}>
            <Grid container spacing={2}>
              <Grid item md={5}>
                <Paper className={classes.dashboard_card}>
                  <Box
                    className={classes.card_header}
                    sx={{
                      borderRight: "4px solid #14D4E2",
                    }}
                  >
                    <img src={Clock} alt="" />
                    <Typography
                      variant="h6"
                      className={classes.card_header_heading}
                    >
                      Today's Check In
                    </Typography>
                  </Box>
                  <Box className="checkIn_list">
                    {dashboard &&
                      dashboard?.presents?.presents?.map((checkIn, i) => (
                        <>
                          {(checkIn?.status !== "On Leave" ||
                            checkIn?.status !== "On Half Day Leave" ||
                            checkIn?.status !== "Holiday") && (
                            <Box sx={{ my: 1, pr: 2 }} key={i}>
                              <Box className={classes.attendance_list_item}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Avatar
                                    src={
                                      checkIn?.user?.profile_picture[0]
                                        ?.fileName
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      navigate(
                                        `/HR/employee/${checkIn.user?.userId}`
                                      )
                                    }
                                    sx={{ pl: 1 }}
                                    className="fs-14 link__color"
                                  >
                                    {checkIn.user.full_name}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <RxDotFilled
                                    style={{
                                      color: `${
                                        checkIn.status === "present"
                                          ? "#59C680"
                                          : checkIn.status === "late"
                                          ? "#1168ED"
                                          : "#F2B225"
                                      } `,
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      color: `${
                                        checkIn.status === "present"
                                          ? "#59C680"
                                          : checkIn.status === "late"
                                          ? "#1168ED"
                                          : "#F2B225"
                                      } `,
                                      pl: 1,
                                    }}
                                    className="fs-14"
                                  >
                                    {checkIn.status}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </>
                      ))}
                  </Box>
                </Paper>
                <Paper sx={{ my: 2 }} className={classes.dashboard_card}>
                  <Box
                    className={classes.card_header}
                    sx={{
                      borderRight: "4px solid red !important",
                    }}
                  >
                    <img src={RedClock} alt="" />
                    <Typography
                      variant="h6"
                      className={classes.card_header_heading}
                    >
                      Today's Absents
                    </Typography>
                  </Box>
                  <Box className="checkIn_list">
                    {dashboard &&
                      dashboard?.absents?.absents.map((absent, i) => (
                        <Box sx={{ my: 1, pr: 2 }} key={i}>
                          <Box className={classes.attendance_list_item}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Avatar
                                src={absent.user?.profile_picture[0]?.fileName}
                              />
                              <Typography
                                onClick={() =>
                                  navigate(
                                    `/HR/employee/${absent.user?.userId}`
                                  )
                                }
                                sx={{ pl: 1 }}
                                className="fs-14 link__color"
                              >
                                {absent?.user.full_name}
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <RxDotFilled style={{ color: "#FE022C" }} />
                              <Typography
                                sx={{ color: "#FE022C", pl: 1 }}
                                className="fs-14"
                              >
                                {absent.status}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      ))}
                  </Box>
                </Paper>
              </Grid>
              <Grid item md={7}>
                <Paper
                  sx={{
                    p: 2,
                    borderRadius: "20px",
                    boxShadow:
                      "rgb(50 50 93 / 10%) 0px 0px 100px -20px, rgb(0 0 0 / 20%) 0px 30px 23px -30px !important",
                  }}
                  className="react-big-calendar"
                >
                  <Calendar
                    holidays={dashboard?.holidays}
                    birthdays={dashboard?.birthdays}
                    workAnniversaries={dashboard?.workAnniversaries}
                  />
                </Paper>
              </Grid>
            </Grid>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Paper
                    sx={{
                      width: "100%",
                      my: 2,
                      borderRadius: "20px",
                      px: 2,
                      py: 1,
                      boxShadow:
                        "rgb(50 50 93 / 10%) 0px 0px 100px -20px, rgb(0 0 0 / 20%) 0px 30px 23px -30px !important",
                    }}
                  >
                    <Box
                      className={classes.card_header}
                      sx={{
                        borderRight: "4px solid #8C63EE !important",
                      }}
                    >
                      <img src={Ticket} alt="" />
                      <Typography
                        variant="h6"
                        className={classes.card_header_heading}
                      >
                        New Tickets
                      </Typography>
                    </Box>
                    <Box
                      sx={{ height: "230px", overflowY: "scroll", my: 1 }}
                      className="tickets_box"
                    >
                      <table
                        id="dashboard_leaves_table"
                        style={{ width: "100%" }}
                      >
                        <tr style={{ textAlign: "left", padding: "20px 0px" }}>
                          <th>
                            {" "}
                            <Typography
                              variant="h6"
                              className={`${classes.leave_card_heading} fs-14`}
                            >
                              Assign By
                            </Typography>
                          </th>

                          <th>
                            {" "}
                            <Typography
                              variant="h6"
                              className={`${classes.leave_card_heading} fs-14`}
                            >
                              Subject
                            </Typography>
                          </th>

                          <th>
                            <Typography
                              variant="h6"
                              className={`${classes.leave_card_heading}`}
                            >
                              Priority
                            </Typography>
                          </th>
                        </tr>
                        {dashboard &&
                          dashboard?.tickets?.map((ticket, i) => (
                            <tr key={i}>
                              <td>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    py: 1,
                                  }}
                                >
                                  <Avatar
                                    src={
                                      ticket?.assignBy?.profile_picture[0]
                                        ?.fileName
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      navigate(
                                        `/HR/employee/${ticket?.assignBy?.userId}`
                                      )
                                    }
                                    sx={{ pl: 1 }}
                                    className="fs-14 link__color"
                                  >
                                    {ticket?.assignBy?.full_name}
                                  </Typography>
                                </Box>
                              </td>

                              <td>
                                <Typography className="fs-12" sx={{ py: 1 }}>
                                  {ticket?.subject}
                                </Typography>
                              </td>

                              <td>
                                <Box
                                  sx={{
                                    width: "fit-content",
                                    padding: "5px 15px",
                                    background: `${
                                      ticket?.priority === "medium"
                                        ? "#f7941d"
                                        : ticket?.priority === "low"
                                        ? "rgb(89, 198, 128)"
                                        : ticket?.priority === "critical"
                                        ? "rgb(139, 101, 239)"
                                        : ticket?.priority === "high"
                                        ? "#ff0000"
                                        : null
                                    }`,
                                    borderRadius: "20px",
                                    color: "white",
                                  }}
                                >
                                  <Typography className="fs-11">
                                    {ticket?.priority}
                                  </Typography>
                                </Box>
                              </td>
                            </tr>
                          ))}
                      </table>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={7}>
                  <Paper
                    sx={{
                      width: "100%",
                      my: 2,
                      borderRadius: "20px",
                      px: 2,
                      py: 1,
                      boxShadow:
                        "rgb(50 50 93 / 10%) 0px 0px 100px -20px, rgb(0 0 0 / 20%) 0px 30px 23px -30px !important",
                    }}
                  >
                    <Box className={classes.leave_card_header}>
                      <img src={Leave} alt="" />
                      <Typography
                        variant="h5"
                        className={classes.card_header_heading}
                        sx={{ color: "white" }}
                      >
                        Leaves
                      </Typography>
                    </Box>
                    <Box
                      sx={{ height: "230px", overflowY: "scroll", my: 1 }}
                      className="tickets_box"
                    >
                      <table
                        id="dashboard_leaves_table"
                        style={{ width: "100%" }}
                      >
                        <tr style={{ textAlign: "left", padding: "20px 0px" }}>
                          <th>
                            {" "}
                            <Typography
                              variant="h6"
                              className={`${classes.leave_card_heading} fs-14`}
                            >
                              Employee
                            </Typography>
                          </th>
                          <th>
                            {" "}
                            <Typography
                              variant="h6"
                              className={`${classes.leave_card_heading} fs-14`}
                            >
                              Date
                            </Typography>
                          </th>
                          <th>
                            {" "}
                            <Typography
                              variant="h6"
                              className={`${classes.leave_card_heading} fs-14`}
                            >
                              Department
                            </Typography>
                          </th>
                          <th>
                            <Typography
                              variant="h6"
                              className={`${classes.leave_card_heading} fs-14`}
                            >
                              HOD Status
                            </Typography>
                          </th>
                          <th>
                            <Typography
                              variant="h6"
                              className={`${classes.leave_card_heading} fs-14`}
                            >
                              HR Status
                            </Typography>
                          </th>
                        </tr>
                        {dashboard &&
                          dashboard?.appliedleaves?.map((leave, i) => (
                            <tr key={i}>
                              <td>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    py: 1,
                                  }}
                                >
                                  <Avatar
                                    src={
                                      leave?.user?.profile_picture[0]?.fileName
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      navigate(
                                        `/HR/employee/${leave.user?.userId}`
                                      )
                                    }
                                    sx={{ pl: 1 }}
                                    className="fs-14 link__color"
                                  >
                                    {leave?.user?.full_name}
                                  </Typography>
                                </Box>
                              </td>
                              <td>
                                <Typography className="fs-12" sx={{ py: 1 }}>
                                  {moment(leave?.start_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </Typography>
                              </td>
                              <td>
                                <Typography className="fs-12" sx={{ py: 1 }}>
                                  {leave?.user?.department?.department_name}
                                </Typography>
                              </td>
                              <td>
                                <Typography
                                  className={`${
                                    leave?.line_manager_status === "approved"
                                      ? classes.approved_status
                                      : leave?.line_manager_status ===
                                        "rejected"
                                      ? classes.rejected_status
                                      : classes.pending_status
                                  } fs-12`}
                                >
                                  {leave?.line_manager_status}
                                </Typography>
                              </td>
                              <td>
                                <Typography
                                  className={`${
                                    leave?.hr_status === "approved"
                                      ? classes.approved_status
                                      : leave?.hr_status === "rejected"
                                      ? classes.rejected_status
                                      : classes.pending_status
                                  } fs-12`}
                                >
                                  {leave?.hr_status}
                                </Typography>
                              </td>
                            </tr>
                          ))}
                      </table>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      width: "100%",
                      my: 2,
                      borderRadius: "20px",
                      px: 2,
                      py: 1,
                      boxShadow:
                        "rgb(50 50 93 / 10%) 0px 0px 100px -20px, rgb(0 0 0 / 20%) 0px 30px 23px -30px !important",
                    }}
                  >
                    <Box
                      className={classes.card_header}
                      sx={{
                        borderRight: "4px solid #8C63EE !important",
                      }}
                    >
                      <img src={Ticket} alt="" />
                      <Typography
                        variant="h6"
                        className={classes.card_header_heading}
                      >
                        Requests
                      </Typography>
                    </Box>
                    <Box
                      sx={{ height: "230px", overflowY: "scroll", my: 1 }}
                      className="tickets_box"
                    >
                      <table
                        id="dashboard_leaves_table"
                        style={{ width: "100%" }}
                      >
                        <tr style={{ textAlign: "left", padding: "20px 0px" }}>
                          <th>
                            {" "}
                            <Typography
                              variant="h6"
                              className={`${classes.leave_card_heading} fs-14`}
                            >
                              Request By
                            </Typography>
                          </th>

                          <th>
                            {" "}
                            <Typography
                              variant="h6"
                              className={`${classes.leave_card_heading} fs-14`}
                            >
                              Subject
                            </Typography>
                          </th>

                          <th>
                            <Typography
                              variant="h6"
                              className={`${classes.leave_card_heading}`}
                            >
                              Request Date
                            </Typography>
                          </th>
                        </tr>
                        {dashboard &&
                          requests?.map((r, i) => (
                            <tr key={i}>
                              <td>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    py: 1,
                                  }}
                                >
                                  <Avatar
                                    src={
                                      r?.request_by?.profile_picture[0]
                                        ?.fileName
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      navigate(
                                        `/HR/employee/${r?.request_by?.userId}`
                                      )
                                    }
                                    sx={{ pl: 1 }}
                                    className="fs-14 link__color"
                                  >
                                    {r?.request_by?.full_name}
                                  </Typography>
                                </Box>
                              </td>

                              <td>
                                <Typography className="fs-12" sx={{ py: 1 }}>
                                  {r?.subject}
                                </Typography>
                              </td>

                              <td>
                                <Typography className="fs-12" sx={{ py: 1 }}>
                                  {new Date(
                                    r?.request_date
                                  ).toLocaleDateString()}
                                </Typography>
                              </td>
                            </tr>
                          ))}
                      </table>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
